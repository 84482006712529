<template>
  <div>
    <a-descriptions
      :column="1"
      class="iotplt-descriptions iotplt-descriptions-max-10"
    >
      <a-descriptions-item label="事件性质">
        {{ eventName }}
      </a-descriptions-item>

      <a-descriptions-item label="账单月份">
        {{ data.month }}
      </a-descriptions-item>

      <a-descriptions-item label="月账单状态">
        {{ data.status }}
      </a-descriptions-item>

      <a-descriptions-item>
        <span slot="label">
          <a-tooltip>
            <template slot="title">
              未出账时停机保号、流量使用详情账单金额不计入月账单结算金额
            </template>
            月账单结算金额(元)
            <a-icon type="question-circle" />
          </a-tooltip>
        </span>
        {{ data.total_fee | formatCurrency }}
      </a-descriptions-item>
    </a-descriptions>

    <a-table
      size="middle"
      :columns="tableColumns"
      :data-source="tableData"
      :pagination="false"
      row-key="index"
    />
  </div>
</template>

<script>
import { formatFloat } from '@/utils/filter'

export default {
  name: 'BalanceStatisticTopAgentViewAgencyMonthlyBillInfo',
  data() {
    return {
      tableData: []
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          title: '单元类型',
          dataIndex: 'unit_type'
        },
        {
          title: '账单个数',
          dataIndex: 'bill_count'
        },
        {
          title: '结算金额(元)',
          customRender: formatFloat,
          dataIndex: 'total_fee'
        }
      ]
    },

    eventName() {
      return `${this.data.month}月结算账单`
    }
  },
  created() {
    this.assembleTableData()
  },
  methods: {
    assembleTableData() {
      this.tableData = [
        {
          index: 0,
          unit_type: '划拨账单',
          bill_count: this.data.allocated_count,
          total_fee: this.data.allocated_fee
        },
        {
          index: 1,
          unit_type: '激活账单',
          bill_count: this.data.actived_count,
          total_fee: this.data.actived_fee
        },
        {
          index: 2,
          unit_type: '续期账单',
          bill_count: this.data.renewed_count,
          total_fee: this.data.renewed_fee
        },
        {
          index: 3,
          unit_type: '订购账单',
          bill_count: this.data.subscribed_count,
          total_fee: this.data.subscribed_fee
        },
        {
          index: 4,
          unit_type: '停机保号账单',
          bill_count: this.data.stopped_count,
          total_fee: this.data.stopped_fee
        },
        {
          index: 5,
          unit_type: '流量使用详情账单',
          bill_count: this.data.card_used_count,
          total_fee: this.data.card_used_fee
        }
      ]
    }
  }
}
</script>

